<template>
    <div class="flex-box vertical content md">
        <div class="data-title">
            <div class="rec-title-text">共{{pagination.total}}条记录</div>
            <a-form-model class="query-form" layout="inline">
                <a-form-model-item label="日期">
                    <a-range-picker
                        v-model="form.time"
                        :placeholder="['开始时间', '结束时间']"
                        format="YYYY-MM-DD"
                        value-format="YYYY-MM-DD"
                        @change="getList"/>
                </a-form-model-item>
            </a-form-model>
            <div class="title-btn">
                <a-button type="primary" @click="getList">查询</a-button>
            </div>
        </div>
        <div class="data-area">
            <a-table
                    ref="dataTable"
                    :columns="columns"
                    row-key="id"
                    :data-source="list"
                    :loading="loading"
                    :pagination="pagination"
                    :scroll="{y: h}"
                    @change="handleTableChange">
                <template v-slot:name="text">
                    <open-data type="userName" :openid="text" />
                </template>
                <template v-slot:action="text, record">
                    <div class="row-btn">
                        <a class="txt-btn" @click.stop="showDetail(record)">查看</a>
                    </div>
                </template>
            </a-table>
        </div>
        <a-modal
            title="祈福详情"
            :width="480"
            v-model="visible">
            <div class="wish-detail" v-if="wish">
                <div class="detail-info-item">
                    <div class="detail-info-label">日期</div>
                    <div class="detail-info-value">{{wish.create_time}}</div>
                </div>
                <div class="detail-info-item">
                    <div class="detail-info-label">姓名</div>
                    <div class="detail-info-value">{{wish.name}}</div>
                </div>
                <div class="detail-info-item">
                    <div class="detail-info-label">心愿</div>
                    <div class="detail-info-value">{{wish.wish || '无'}}</div>
                </div>
            </div>
        </a-modal>
    </div>
</template>

<script>
    export default {
        name: "RecWish",
        data() {
            return {
                h: 500,
                list: [],
                form: {},
                loading: false,
                columns: [
                    {title: '时间', dataIndex: 'create_time'},
                    {title: '姓名', dataIndex: 'name'},
                    {title: '心愿', dataIndex: 'wish'},
                    {title: '操作', key: 'action', scopedSlots: { customRender: 'action'}}
                ],
                pagination: {
                    total: 0,
                    current: 1,
                    pageSize: 10,
                    showSizeChanger: true
                },
                wish: null,
                visible: false
            }
        },
        created() {
            this.getList();
        },
        mounted() {
            this.setHeight();
            window.addEventListener("resize", this.setHeight, false);
        },
        beforeDestroy() {
            window.removeEventListener("resize", this.setHeight, false);
        },
        methods: {
            showDetail(record) {
                this.wish = record;
                this.visible = true;
            },
            handleTableChange(pagination) {
                let pager = { ...this.pagination };
                pager.current = pagination.current;
                pager.pageSize = pagination.pageSize;
                this.pagination = pager;
                this.getList();
            },
            getList() {
                this.loading = true;
                let url = `/admin/mini-wish?sort=-id`;
                let {current, pageSize} = this.pagination;
                url += `&page=${current}&pageSize=${pageSize}`;
                let time = this.form.time;
                if(time && time.length == 2) {
                    let start = time[0] + ' 00:00:00'
                    let end = time[1] + ' 23:59:59'
                    url += `&filter[create_time][gte]=${start}&filter[create_time][lt]=${end}`;
                }
                this.$axios({url, noTempleFilter: true}).then(res => {
                    this.loading = false;
                    let list = res.data;
                    this.list = list;
                    if(res.page) {
                        this.$set(this.pagination, 'total', res.page.totalCount);
                    }
                }).catch(() => {
                    this.loading = false;
                });
            },
            setHeight() {
                let dataTale = this.$refs.dataTable;
                if(dataTale) {
                    let area = this.$el.querySelector(".data-area");
                    this.h = area.clientHeight - 54 - 64;
                }
            },
        }
    }
</script>

<style scoped>

</style>
